<template>
    <v-card-text>
        <span class="text-h5">{{ formTitle }}</span>

        <v-form v-model="isValid">
            <v-container class="py-0">
                <v-row>
                    <v-col
                        cols="12"
                        md="4"
                    >
                        <v-text-field
                            v-model="editedItem.name.value"
                            class="purple-input"
                            :label="$t('admin.companies.name')"
                            :error-messages="editedItem.name.error"
                            :rules="[rules.required]"
                            :disabled="isOnlyView"
                            @keyup="editedItem.name.error = ''"
                        />
                    </v-col>

                    <v-col
                        cols="12"
                        md="4"
                    >
                        <v-text-field
                            v-model="editedItem.title.value"
                            class="purple-input"
                            :label="$t('admin.companies.titleAtt')"
                            :error-messages="editedItem.title.error"
                            :disabled="isOnlyView"
                            @keyup="editedItem.title.error = ''"
                        />
                    </v-col>

                    <v-col
                        cols="12"
                        md="4"
                    >
                        <v-file-input
                            v-model="editedItem.img.value"
                            :label="$t('admin.companies.logo')"
                            accept="image/*"
                            :error-messages="editedItem.img.error"
                            :disabled="isOnlyView"
                            @change="preview_img()"
                        />
                        <v-img
                            class="img_preview"
                            :src="editedItem.img.url"
                            contain
                            height="60px"
                        />
                    </v-col>

                    <v-col
                        cols="12"
                        md="4"
                        lg="4"
                        sm="6"
                    >
                        <div
                            class="mb-1"
                            v-text="$t('admin.companies.color-appbar')"
                        />

                        <v-color-picker
                            v-model="colorAppBarComputed"
                            mode="hexa"
                            :disabled="isOnlyView"
                        />
                    </v-col>

                    <v-col
                        cols="12"
                        md="4"
                        lg="4"
                        sm="6"
                    >
                        <div
                            class="mb-1"
                            v-text="$t('admin.companies.color-background')"
                        />

                        <v-color-picker
                            v-model="colorBackgroundComputed"
                            mode="hexa"
                            :disabled="isOnlyView"
                        />
                    </v-col>

                    <v-col
                        cols="12"
                        md="4"
                        lg="4"
                        sm="6"
                    >
                        <div
                            class="mb-1"
                            v-text="$t('admin.companies.color-primary')"
                        />

                        <v-color-picker
                            v-model="colorPrimaryComputed"
                            mode="hexa"
                            :disabled="isOnlyView"
                        />
                    </v-col>

                    <v-col
                        cols="12"
                        md="4"
                        lg="4"
                        sm="6"
                    >
                        <div
                            class="mb-1"
                            v-text="$t('admin.companies.color-secondary')"
                        />

                        <v-color-picker
                            v-model="colorSecondaryComputed"
                            mode="hexa"
                            :disabled="isOnlyView"
                        />
                    </v-col>

                    <v-col
                        cols="12"
                        md="4"
                        lg="4"
                        sm="6"
                    >
                        <v-file-input
                            v-model="editedItem.banner.value"
                            :label="$t('admin.companies.banner')"
                            accept="image/*"
                            :error-messages="editedItem.banner.error"
                            :disabled="isOnlyView"
                            @change="preview_img('banner')"
                        />
                        <v-img
                            class="img_preview"
                            :src="editedItem.banner.url"
                            contain
                            min-height="60px"
                        />
                    </v-col>

                    <v-col
                        cols="12"
                        md="4"
                        lg="4"
                        sm="6"
                    >
                        <v-file-input
                            v-model="editedItem.bannerMobile.value"
                            :label="$t('admin.companies.bannerMobile')"
                            accept="image/*"
                            :error-messages="editedItem.bannerMobile.error"
                            :disabled="isOnlyView"
                            @change="preview_img('bannerMobile')"
                        />
                        <v-img
                            class="img_preview"
                            :src="editedItem.bannerMobile.url"
                            contain
                            min-height="60px"
                        />
                    </v-col>

                    <v-col cols="12">
                        <div class="text-subtitle d-flex justify-space-between">
                            <span v-text="$t('admin.companies.config')" />
                            <v-btn
                                class="mx-2 pb-0 mb-0"
                                fab
                                dark
                                color="primary"
                                small
                                :disabled="isOnlyView"
                                @click="restoreConfig"
                            >
                                <v-icon dark> mdi-undo </v-icon>
                            </v-btn>
                        </div>

                        <v-textarea
                            name="input-7-1"
                            :value="editedItem.config.value"
                            outlined
                            :disabled="isOnlyView"
                            @change="editorCodeChanged($event)"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
        <v-card-actions>
            <c-btn
                :outlined="false"
                :text="false"
                :dark="true"
                @click="generateStatsFromCurrentPeriod"
            >
                {{ $t('evaluation.stats.generate') }}
            </c-btn>

            <v-spacer />

            <c-btn
                v-if="isOnlyView"
                :outlined="false"
                :text="false"
                :dark="true"
                @click="$router.go(-1)"
            >
                {{ $t('common.endProcess') }}
            </c-btn>
            <c-btn
                v-else
                text
                @click="$router.go(-1)"
            >
                {{ $t('common.confirmDialogCancel') }}
            </c-btn>
            <c-btn
                v-if="!isOnlyView"
                class="ml-4"
                :outlined="false"
                :text="false"
                :dark="isValid || serverCheck"
                :disabled="!isValid && !serverCheck"
                @click="save"
            >
                {{ $t('common.dialogSave') }}
            </c-btn>
        </v-card-actions>
    </v-card-text>
</template>

<script>
import axios from '@/plugins/axios';
import rules from '@/helpers/rulesValidate';
import { mapGetters } from 'vuex';
import PostsComponent from '@/components/admin/posts/postsList';
import EvaluationsAPIService from '@/services/EvaluationsAPIService';

export default {
    name: 'basicInfo',
    components: { PostsComponent },
    props: {
        item: Object,
        companyId: {
            type: Number | String,
            required: true
        }
    },
    data: function () {
        return {
            urlMain: 'companies',
            rules: {
                ...rules
            },
            editedItem: this.getEditedItem(),
            isValid: true,
            serverCheck: false,
            isOnlyView: false,

            games: []
            /*  types: ['hex', 'hexa', 'rgba', 'hsla', 'hsva'],
    type: 'hex',
    hex: '#FF00FF'
   hexa: '#FF00FFFF',
  rgba: { r: 255, g: 0, b: 255, a: 1 }
   hsla: { h: 300, s: 1, l: 0.5, a: 1 },
  hsva: { h: 300, s: 1, v: 1, a: 1 } */
        };
    },
    computed: {
        ...mapGetters(['getCompanyId']),
        ...mapGetters(['getThemeMode']),

        formTitle() {
            if (this.item) {
                return this.isOnlyView ? this.$t('admin.companies.view') : this.$t('admin.companies.edit');
            } else return this.$t('admin.companies.new');
        },
        gamesList() {
            return this.games.map(game => ({
                id: game.id,
                displayValue: `${game.id}-${game.type.name} (${game.teams_count} Equipos)`
            }));
        },

        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light';
        },
        colorAppBarComputed: {
            get() {
                let color = this.editedItem?.colorAppBar?.value;
                if (color) return color;

                color = this.getThemeMode?.appBarColor;
                while (color.length < 9) color += 'F';

                return color;
            },
            set(v) {
                this.editedItem.colorAppBar.value = v;
            }
        },
        colorBackgroundComputed: {
            get() {
                let color = this.editedItem.colorBackground.value;
                if (color) return color;

                color = this.$vuetify.theme.themes[this.theme].background;
                while (color.length < 9) color += 'F';

                return color;
            },
            set(v) {
                this.editedItem.colorBackground.value = v;
            }
        },
        colorPrimaryComputed: {
            get() {
                let color = this.editedItem.colorPrimary.value;
                if (color) return color;

                color = this.$vuetify.theme.themes[this.theme].primary;
                while (color.length < 9) color += 'F';

                return color;
            },
            set(v) {
                this.editedItem.colorPrimary.value = v;
            }
        },
        colorSecondaryComputed: {
            get() {
                let color = this.editedItem.colorSecondary.value;
                if (color) return color;

                color = this.$vuetify.theme.themes[this.theme].secondary;
                while (color.length < 9) color += 'F';

                return color;
            },
            set(v) {
                this.editedItem.colorSecondary.value = v;
            }
        }
    },
    watch: {
        item: {
            handler(newValue) {
                this.editedItem = this.getEditedItem(newValue);
            },
            immediate: true
        }
    },
    methods: {
        getImageRoute() {
            return process.env.VUE_APP_API_ENDPOINT + 'media/imagesSimple?filename=companies/images/';
        },
        getBannerRoute() {
            return process.env.VUE_APP_API_ENDPOINT + 'media/imagesSimple?filename=companies/images/banner/';
        },
        getBannerMobileRoute() {
            return process.env.VUE_APP_API_ENDPOINT + 'media/imagesSimple?filename=companies/images/banner_mobile/';
        },
        getEditedItem(itemInit = null) {
            const item = itemInit;

            var empty = !item;

            return {
                id: empty ? '' : item.id,
                name: {
                    value: empty ? '' : item.attributes.name,
                    error: ''
                },
                title: {
                    value: empty ? '' : item.attributes.title,
                    error: ''
                },
                img: {
                    value:
                        empty || !item.attributes.logo
                            ? []
                            : new File([item.attributes.logo], item.attributes.logo, {
                                  type: 'text/plain'
                              }),
                    error: '',
                    url: empty || !item.attributes.logo ? '' : this.getImageRoute() + item.attributes.logo
                },
                config: {
                    value: empty ? '' : item.attributes.config ?? '',
                    error: ''
                },
                colorAppBar: {
                    value: empty ? '' : item.attributes['color-appbar'] || item.attributes.color_appbar,
                    error: ''
                },
                colorBackground: {
                    value: empty ? '' : item.attributes['color-background'] || item.attributes.color_background,
                    error: ''
                },
                colorPrimary: {
                    value: empty ? '' : item.attributes['color-primary'] || item.attributes.color_primary,
                    error: ''
                },
                colorSecondary: {
                    value: empty ? '' : item.attributes['color-secondary'] || item.attributes.color_secondary,
                    error: ''
                },
                banner: {
                    value:
                        empty || !item.attributes.banner
                            ? []
                            : new File([item.attributes.banner], item.attributes.banner, {
                                  type: 'text/plain'
                              }),
                    error: '',
                    url: empty || !item.attributes.banner ? '' : this.getBannerRoute() + item.attributes.banner
                },
                bannerMobile: {
                    value:
                        empty || !item.attributes['banner-mobile']
                            ? []
                            : new File([item.attributes['banner-mobile']], item.attributes['banner-mobile'], {
                                  type: 'text/plain'
                              }),
                    error: '',
                    url:
                        empty || !item.attributes['banner-mobile']
                            ? ''
                            : this.getBannerMobileRoute() + item.attributes['banner-mobile']
                }
            };
        },
        preview_img(imageAtt = 'img') {
            if (this.editedItem[imageAtt].value && !(this.editedItem[imageAtt].value instanceof Array)) {
                this.editedItem[imageAtt].url = URL.createObjectURL(this.editedItem[imageAtt].value);
            } else {
                this.editedItem[imageAtt].url = null;
                this.editedItem[imageAtt].value = [];
            }
        },
        initialize() {},
        async save() {
            const formData = new FormData();
            formData.append('name', this.editedItem.name.value);
            formData.append('title', this.editedItem.title.value ?? '');
            formData.append('image', this.editedItem.img.value ?? '');
            formData.append('colorAppBar', this.colorAppBarComputed);
            formData.append('colorBackground', this.colorBackgroundComputed);
            formData.append('colorPrimary', this.colorPrimaryComputed);
            formData.append('colorSecondary', this.colorSecondaryComputed);
            formData.append('config', this.editedItem.config.value);
            formData.append('banner', this.editedItem.banner.value ?? '');
            formData.append('bannerMobile', this.editedItem.bannerMobile.value ?? '');

            // if (this.editedItem.game_id.value) { formData.append("game_id", this.editedItem.game_id.value); }

            let urlAppend = '';
            let method = 'POST';
            if (this.editedItem.id) {
                urlAppend = '/' + this.editedItem.id;
                method = 'PATCH';

                formData.append('_method', method);
            }

            /* console.log('********** formData ********')
      console.log(formData) */

            try {
                const result = await axios.post('configs/' + this.urlMain + urlAppend, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });

                /* const result = await axios({
            method: method,
            url: this.urlMain + urlAppend,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }

        }) */

                /* console.log('********** result ********')
        console.log(result) */

                if (result.status === 200 || result.status === 201 || result.status === 204) {
                    this.viewCompany(result.data);

                    const actionMSG = this.editedItem.id ? 'updatedOK' : 'createdOK';
                    this.$store.dispatch('snackbar/success', this.$t('common.' + actionMSG));
                }
            } catch (error) {
                /* console.log('********** errors ********')
        console.log(error) */

                if (error?.response?.status === 422) {
                    /* if (error.response?.data?.errors?.length) {
              error.response.data.errors.forEach((e) => {
                  if (e.source.pointer === '/data/attributes/title') {
                      this.editedItem.title.error = e.detail
                  }
                  if (e.source.pointer === '/data/attributes/body') {
                      this.editedItem.body.error = e.detail
                  }
              })
          } */
                }

                this.$store.dispatch('snackbar/error', this.$t('common.accionError'));
            }
        },
        viewCompany(item) {
            const data = {
                id: item.company.id,
                attributes: {
                    ...item.company
                }
            };

            this.isOnlyView = true;

            this.$emit('update:companyId', data.id);

            this.editedItem = this.getEditedItem(data);

            this.$emit('update:item', data);
            if (this.$route.params.id === 'new') {
                this.$router.replace({ params: { id: data.id } });
            }
        },

        restoreConfig() {
            try {
                this.editedItem.config.value = this.item.attributes.config;
            } catch (e) {}
        },
        editorCodeChanged(element) {
            this.editedItem.config.value = element;
        },

        async generateStatsFromCurrentPeriod() {
            const response = await EvaluationsAPIService.generateStatsFromCurrentPeriod({
                companyId: this.getCompanyId,
                gameId: 51
            });

            const result = response.data;

            console.log('generateStatsFromCurrentPeriod', result);
        }
    }
};
</script>

<style>
/*iframe.ql-video {
  width: 500px !important;
  height: 500px !important;
}*/

.quill-editor iframe {
    pointer-events: none;
}
</style>
